






















import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniPagination from "@/components/table/SygniPagination.vue";
import SygniAccessModal from "@/components/layout/SygniAccessModal.vue";
import InvestorsBlindTable from "@/modules/genprox/modules/fund/modules/capital-rise/components/InvestorsBlindTable.vue";
import InvestorsBlindFilters from "@/modules/genprox/modules/fund/modules/capital-rise/components/InvestorsBlindFilters.vue";
import RegisterationForm from "@/modules/auth/components/Register.vue";
import {Investor} from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";
import {mapState} from "vuex";


@Component({
  components: {
    RegisterationForm,
    InvestorsBlindFilters,
    InvestorsBlindTable,
    SygniPagination,
    UserPresentation,
    SygniContainerTitle, 
    SygniRoundedButton,
    SygniAccessModal
  },
  computed: mapState('investors', {
    items: (state: any) => state.investors,
    isBusy: (state: any) => state.investorsTableBusy,
  }),
})
export default class MarketplaceBlindModule extends Vue {

  items!: Investor[];
  isBusy!: boolean;
}
