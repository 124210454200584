











































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import { Statues } from "@/shared/interfaces/Statues";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {BSpinner, BvTableField} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/capital-rise/components/InvestorsInnerTable.vue";
import {mapState} from "vuex";
import SygniTableLegacy from "@/components/table/SygniTableLegacy.vue";

@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner },
    computed: mapState('investors', {
      itemsLength: (state: any) => state.investorsLength,
    }),
})
export default class InvestorsBlindTable extends SygniTableLegacy<MarketplaceItem> {

  tableItems: Array<any> = [
    {
      "photo": require('@/assets/images/avatar-1.jpg'),
      "totalInvestmentValue": 250000,
      "productStatuses": [
        "AWAITING"
      ],
      "id": "f42a23bf-5efe-432b-86d4-595416f3ec39",
      "name": "Test4 Genprox",
      "status": "INVITED",
      "sourceType": "NATURAL_PERSON",
      "sourceName": "Leo Hansen",
      "created": "2022-04-07T10:42:12+00:00"
    },
    {
      "photo": require('@/assets/images/avatar-2.jpg'),
      "totalInvestmentValue": 0,
      "productStatuses": [],
      "id": "48887252-86b5-4a78-9d5e-2e9ac446300f",
      "name": "Test3 Genprox",
      "status": "LEAD",
      "sourceType": "NATURAL_PERSON",
      "sourceName": "Leo Hansen",
      "created": "2022-04-07T10:41:39+00:00"
    },
    {
      "photo": require('@/assets/images/avatar-3.jpg'),
      "totalInvestmentValue": 0,
      "productStatuses": [],
      "id": "01690551-a56c-4aa3-9333-30361f25312c",
      "name": "Test2 Genprox",
      "status": "LEAD",
      "sourceType": "NATURAL_PERSON",
      "sourceName": "Leo Hansen",
      "created": "2022-04-07T10:41:23+00:00"
    },
    {
      "photo": require('@/assets/images/avatar-4.jpg'),
      "totalInvestmentValue": 0,
      "productStatuses": [],
      "id": "d5aab266-9f70-49a8-af2d-86bfe2351d95",
      "name": "Test1 Genprox",
      "status": "LEAD",
      "sourceType": "NATURAL_PERSON",
      "sourceName": "Leo Hansen",
      "created": "2022-04-07T10:41:02+00:00"
    }
  ];

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'name', sortable: true, label: 'Investor'},
    { key: 'status', sortable: true, borderless: true },
    { key: 'sourceName', sortable: true, borderless: true , label: 'source' },
    { key: 'created', sortable: true },
    { key: 'productStatuses', sortable: false },
    { key: 'totalInvestmentValue', sortable: false },
  ];

  statusClass(status: Statues) {
    switch(status) {
      case(Statues.active): return 'primary';
      case(Statues.invited): return 'success';
      case(Statues.awaiting): return 'success';
      case(Statues.pending): return 'black';
      case(Statues.rejected): return 'danger';
      case(Statues.declined): return 'disabled';
    }
    return '';
  }

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  mounted(){
    this.onMounted();
    this.setBorderlessTds();
  }
}

export interface MarketplaceItem {
}

